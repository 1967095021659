<template>
  <b-custom-tabs id="customerDetailTabs" :tabs="tabs" lazy />
</template>

<script>
import CustomerDetailModal from "./CustomerDetailModal.vue";
import CustomerLogs from "./CustomerLogs.vue";
export default {
  data() {
    return {
      tabs: [
        { title: "Genel Bilgiler", component: CustomerDetailModal },
        { title: "Girişler", component: CustomerLogs },
      ],
    };
  },
};
</script>

<style></style>
