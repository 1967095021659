<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="customerval">
      <app-select-input
        v-if="!selectedCompany && !updateItem"
        v-model="data.company"
        :reduce="(option) => option._id"
        select_label="name"
        :options="companyOptions"
        name="Firma"
        placeholder="Firma Seçiniz..."
        label="Firma:"
        rules="required"
        :loading="isCompanyOptionsLoading"
      />
      <auth-form v-model="auth" />
      <app-select-input
        v-model="auth.role"
        :reduce="(option) => option._id"
        name="Rol"
        label="Rol:"
        placeholder="Rol Seçiniz..."
        :options="roleOptions"
        select_label="value"
        rules="required"
        :loading="isRoleOptionsLoading"
      />
      <app-input v-model="data.duty" name="Unvan" label="Unvan:" placeholder="Unvan..." />
      <hr />
      <app-button :text="updateItem ? 'Güncelle' : 'Oluştur'" block @click="updateItem ? updateUser() : newUser()" />
      <hr />
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import AuthForm from "../AuthForm.vue";

export default {
  components: {
    ValidationObserver,
    AuthForm,
  },
  data() {
    return {
      data: {},
      auth: {},
      update: false,
      companyOptions: [],
      roleOptions: [],
      isCompanyOptionsLoading: false,
      isRoleOptionsLoading: false,
    };
  },
  computed: {
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    callback() {
      return this.$store.getters.appModalItem?.callback ?? this.$store.getters.appSidebarItem?.callback;
    },
    updateItem() {
      return this.$store.getters.appModalItem?.data;
    },
  },
  methods: {
    setData() {
      return {
        auth: {
          ...this.auth,
          phone: this.auth.phone.replaceAll(" ", ""),
        },
        data: {
          ...this.data,
          company: this.selectedCompany?._id ?? this.data.company,
        },
      };
    },
    newUser() {
      this.$validate(this.$refs.customerval, () => {
        this.$store.dispatch("newCustomer", { data: this.setData(), actionType: "setNewCustomer", callback: this.callback });
      });
    },
    updateUser() {
      this.$validate(this.$refs.customerval, () => {
        this.$store.dispatch("updateCustomer", { data: this.setData(), id: this.updateItem?._id, callback: this.callback });
      });
    },
    getCompanyOptions() {
      this.isCompanyOptionsLoading = true;
      this.$axios
        .get("/company/list/company-options")
        .then((result) => {
          this.isCompanyOptionsLoading = false;
          this.companyOptions = result.data;
        })
        .catch((err) => {
          this.isCompanyOptionsLoading = false;
          console.log(err);
        });
    },
    getRoleOptions() {
      this.isRoleOptionsLoading = true;
      this.$axios
        .get("/permissions/roles", { params: { group: "customer" } })
        .then((data) => {
          this.isRoleOptionsLoading = false;
          this.roleOptions = data.data;
        })
        .catch((error) => {
          this.isRoleOptionsLoading = false;
          console.log(error);
        });
    },
  },
  mounted() {
    if (this.updateItem) {
      this.$nextTick(() => {
        this.auth = {
          ...this.updateItem?.auth,
          role: this.updateItem?.auth?.role?._id ?? "",
        };
      });
      this.data = {
        company: this.updateItem?.company?._id ?? "",
        duty: this.updateItem?.duty ?? "",
      };
    }

    if (!this.selectedCompany) {
      this.getCompanyOptions();
    } else this.data.company = this.selectedCompany._id;

    this.getRoleOptions();
  },
};
</script>
