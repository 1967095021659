<template>
  <app-overlay loading-variable="modalOverlay">
    <b-card body-class="p-50" class="mb-50 border">
      <app-detail-table :data="item" :fields="fields">
        <template #isActive="data">
          <b-badge :variant="data.isActive ? 'success' : 'secondary'">
            {{ data.isActive ? "Aktif" : "Arşivlendi" }}
          </b-badge>
        </template>
        <template #usingPanel="data">
          <b-form-checkbox
            v-model="data.auth.allowed"
            @change="changeStatus(data, $event)"
            class="custom-control-success"
            name="check-button"
            switch
            :disabled="!data.auth.usingPanel || !data.isActive"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
      </app-detail-table>
      <div v-if="item.isActive">
        <hr class="mt-0" />
        <b-row>
          <b-col>
            <app-button @click="showSendSmsToUserSidebar" text="SMS Gönder" icon="MessageCircleIcon" block />
          </b-col>
          <b-col>
            <app-button @click="$showAppModalSidebar('Güncelle', customerForm)" text="Düzenle" icon="EditIcon" block />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </app-overlay>
</template>

<script>
import customerForm from "./customerForm.vue";
import SendSmsToUserForm from "./SendSmsToUserForm.vue";

export default {
  data() {
    return {
      customerForm,
      fields: [
        { key: "company.name", label: "Firma Adı" },
        { key: "auth.fullname", label: "İsim" },
        { key: "auth.email", label: "E-Posta" },
        { key: "auth.phone", label: "Telefon" },
        { key: "auth.role.value", label: "Rol" },
        { key: "duty", label: "Unvan" },
        { key: "createdAt", label: "Eklenme Tarihi", formatDateTime: true },
        { key: "auth.dateOfFirstClickSms", label: "Sms Tıklanma", formatDateTime: true },
        { key: "auth.dateOfChangeTempPassword", label: "Şifre Belirleme", formatDateTime: true },
        { key: "auth.lastLogin.createdAt", label: "Son Başarılı Giriş", formatDateTime: true },
        { key: "usingPanel", label: "Panel Kullanımı" },
        { key: "isActive", label: "Durum" },
      ],
    };
  },
  computed: {
    item() {
      return this.$store.getters.appModalItem?.data;
    },
  },
  methods: {
    changeStatus(item, allowed) {
      this.$axios
        .patch(`/change-user-status/${item?.auth?._id}`, { allowed }, { loading: "modal" })
        .then((res) => {
          this.$emitter.$emit("Notification", {
            variant: "success",
            message: "Panel kullanım durumu başarıyla değiştirildi.",
            title: "İşlem Başarılı",
          });
        })
        .catch((err) => {
          item.auth.allowed = !allowed;
          this.$emitter.$emit("Notification", {
            variant: "danger",
            message: err?.data?.message ?? "Panel kullanım durumu  değiştirilemedi.",
            title: "İşlem Başarısız",
          });
        });
    },
    showSendSmsToUserSidebar() {
      this.$showAppModalSidebar("SMS Gönder", SendSmsToUserForm);
    },
  },
};
</script>

<style></style>
