<template>
  <app-overlay loading-variable="sidebarOverlay">
    <validation-observer ref="SendSmsToUserForm">
      <app-select-input
        v-model="selected"
        name="Gönderim Türü"
        :options="options"
        select_label="value"
        label="* Gönderim Türü:"
        placeholder="Gönderim Türü Seçiniz..."
        rules="required"
        :reduce="(option) => option.key"
      />
      <app-textarea-input v-model="message" v-if="selected === 'custom'" name="Mesaj" label="* Mesaj:" placeholder="Mesaj Giriniz..." rules="required" />
      <hr />
      <app-button @click="sendSms" text="Gönder" size="md" block />
      <hr />
      <b-card v-if="selected" class="border" title="Mesaj İçeriği">
        Sayın {{ item.auth.fullname }}, <br /><br />
        <div v-if="selected === 'usingSystem'">
          Şirket çalışanlarınızın tüm bilgileri parmaklarınızın ucunda!
          <br /><br />
          {{ item.auth.email }} e-posta adresiniz ile hesabınıza https://app.riskomer.com adresi üzerinden erişebilirsiniz.
        </div>
        <div v-if="selected === 'custom'">
          {{ message }}
        </div>
        <br />
        Tüm soru ve isteklerinizi 0850 304 0 304 SMS ve WhatsApp ile gönderebilirsiniz.
      </b-card>
    </validation-observer>
  </app-overlay>
</template>

<script>
import { ValidationObserver } from "vee-validate";
export default {
  components: { ValidationObserver },
  data() {
    return {
      selected: null,
      message: null,
      options: [
        { key: "usingSystem", value: "Sistem Kullanımı Hatırlatma" },
        { key: "custom", value: "Özel" },
      ],
    };
  },
  computed: {
    item() {
      return this.$store.getters.appModalItem?.data;
    },
  },
  methods: {
    sendSms() {
      this.$validate(this.$refs?.SendSmsToUserForm, () => {
        this.$axios
          .post(`/customer/send-sms-to-user/${this?.item?._id}`, { smsType: this.selected, message: this.message }, { loading: "sidebar" })
          .then((result) => {
            this.$emitter.$emit("Notification", { variant: "success" });
            this.$store.commit("cleanAppModalSidebar");
          })
          .catch((err) => {
            this.$emitter.$emit("Notification", { variant: "danger", message: err?.data?.message });
            console.log(err);
          });
      });
    },
  },
};
</script>

<style></style>
