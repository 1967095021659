<template>
  <app-overlay>
    <db-searchable-table
      v-model="filters"
      @changed="this.getData"
      class="overflow-hidden"
      :items="customers"
      :fields="fields"
      :total-rows="total"
      searchable
      hover
      @row-clicked="showCustomerDetailModal"
    >
      <template #modalbutton>
        <app-button
          v-if="!isCompanyArchived"
          class="flex-1-md"
          size="md"
          icon="PlusIcon"
          text="Kullanıcı Ekle"
          @click="$showAppSidebar('Kullanıcı Ekle', customerForm, { callback: getData })"
        />
      </template>
      <template slot="company.name" slot-scope="{ item }">
        <span v-if="item.company.name.length > 20" v-b-tooltip.hover.top="item.company.name">{{ $fn(item.company.name, 20) }}</span>
        <span v-else>
          {{ item.company.name }}
        </span>
      </template>
      <template #isActive="{ item }">
        <b-badge :variant="item.isActive ? 'success' : 'secondary'">
          {{ item.isActive ? "Aktif" : "Arşivlendi" }}
        </b-badge>
      </template>
      <template #actions="{ item }">
        <app-dropdown>
          <b-dropdown-item v-if="item.isActive && item.auth.role.key !== 'smsOfficial'" @click="$store.dispatch('refreshCustomerPassword', item._id)">
            <feather-icon class="mr-25" icon="RefreshCwIcon" />
            Şifre Sıfırla
          </b-dropdown-item>
          <b-dropdown-item v-if="item.isActive" @click="archiveCustomer(item)">
            <feather-icon class="mr-25" icon="ArchiveIcon" />
            Arşivle
          </b-dropdown-item>
          <b-dropdown-item @click="deleteCustomer(item)">
            <feather-icon class="mr-25" icon="TrashIcon" />
            Kaldır
          </b-dropdown-item>
        </app-dropdown>
      </template>
    </db-searchable-table>
  </app-overlay>
</template>

<script>
import customerForm from "./customerForm.vue";
import CustomerDetailTabs from "./CustomerDetailTabs.vue";

export default {
  data() {
    return {
      filters: {},
      customerForm,
    };
  },
  computed: {
    customers() {
      return this.$store.getters.customers?.data ?? [];
    },
    total() {
      return this.$store.getters.customers?.total ?? 0;
    },
    selectedCompany() {
      return this.$store.getters.selectedCompany;
    },
    fields() {
      return [
        ...(this.selectedCompany ? [] : [{ key: "company.name", label: "Şirket", sortable: true }]),
        { key: "auth.fullname", label: "Ad Soyad", sortable: true },
        { key: "auth.role.value", label: "Rol", sortable: true },
        { key: "duty", label: "Görevi", sortable: true },
        { key: "isActive", label: "Durum", sortable: true },
        { key: "createdAt", label: "Eklenme Tarihi", formatDateTime: true, sortable: true },
        { key: "auth.lastLogin.createdAt", label: "Son Giriş", formatDateTime: true, sortable: true },
        { key: "actions", label: "Eylemler" },
      ];
    },
    isCompanyArchived() {
      return this.$store.getters.selectedCompany?.isArchived;
    },
  },
  methods: {
    getData() {
      this.$store.dispatch("getCustomers", {
        ...this.filters,
        company: this.selectedCompany?._id ?? null,
        searchFields: JSON.stringify(["company.name", "auth.fullname", "auth.role.value", "duty"]),
      });
    },
    showCustomerDetailModal(customer) {
      this.$axios
        .get(`/customer/get-one/${customer?._id}`, { loading: "table" })
        .then((result) => {
          this.$showAppModal({
            title: result?.data?.auth?.fullname ?? "Kullanıcı Detayları",
            component: CustomerDetailTabs,
            centered: false,
            size: "lg",
            item: { data: result?.data, callback: this.getData },
          });
        })
        .catch((err) => {
          this.$emitter.$emit("Notification", { variant: "danger", title: "Hata Oluştu", message: err?.data?.message ?? null });
        });
    },
    archiveCustomer(item) {
      this.$confirm({ message: "Kullanıcıyı arşivlemek istediğinize emin misiniz?" }, () => this.$store.dispatch("archiveCustomer", item?._id));
    },
    deleteCustomer(item) {
      this.$confirm({ message: "Kullanıcıyı silmek istediğinize emin misiniz?" }, () => {
        this.$store.dispatch("deleteCustomer", { id: item?._id, callback: this.getData });
      });
    },
  },
  watch: {
    isCompanyArchived() {
      this.getData();
    },
  },
  destroyed() {
    this.$store.commit("setCustomers", []);
  },
};
</script>

<style></style>
