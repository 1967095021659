<template>
  <app-overlay loading-variable="modalOverlay">
    <db-searchable-table class="overflow-hidden" v-model="filters" @changed="getData" :items="items" :fields="fields" :total-rows="totalRows" searchable> </db-searchable-table>
  </app-overlay>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      filters: {},
      totalRows: 0,
    };
  },
  computed: {
    fields() {
      return [
        { key: "createdAt", label: "Giriş", formatDateTime: true, sortable: true },
        { key: "logoutTime", label: "Çıkış", formatDateTime: true, sortable: true },
        { key: "ipAddress", label: "IP Adresi" },
      ];
    },
    user() {
      return this.$store.getters.appModalItem?.data?.auth?._id;
    },
  },
  methods: {
    getData() {
      this.$axios
        .get("/logs/auth", { params: { ...this.filters, user: this.user, searchFields: JSON.stringify(["ipAddress"]) }, loading: "modal" })
        .then((res) => {
          this.items = res.data.data;
          this.totalRows = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style></style>
